import React from "react"
import { css, jsx } from "@emotion/core"

import AboutLayout from "../../components/layouts/aboutLayout"
import Chamber from "../../images/chamber-building.jpg"

const AboutIndexPage = props => (
  <AboutLayout pageTitle="About Us">
    <div>
      <img
        src={Chamber}
        css={{
          display: "inline-block",
          border: "1px solid #000",
          width: "100%",
          float: "left",
          marginRight: "1rem",
          marginBottom: `1rem`,
          "@media (min-width: 800px)": {
            width: `45%`,
            marginBottom: `0`,
          },
        }}
      />
      <p>
        The Claxton-Evans County Chamber of Commerce has been a vital part of
        the community since 1953. It is an organization of businesses,
        organizations and individuals who have joined together with the overall
        objective to foster progressive and orderly community development. It is
        the hub of our community. Efforts on behalf of the Chamber do not stop
        with business. The Chamber works for the good of the entire community.
        More than anything, the Chamber is people…concerned, dedicated and
        committed to support and improve the overall economic strength and
        quality of life for Evans County residents. Our Mission is to promote
        and support business, civic, cultural, and educational growth in our
        community.
        <br />
        <br />
        <br />
      </p>
      <h2>Our History</h2>
      <p>
        The Claxton-Evans County Chamber of Commerce was officially organized on
        April 3, 1953, in a meeting held at the Evans County Courthouse. Ralph
        Dixon was elected to serve as the Chamber’s first President. The Chamber
        was originally housed in a small two-room building, formerly occupied by
        the Claxton Police Department on the corner of Railroad Street and
        Highway 301. Years later, the Chamber moved in a building located at 4
        North Duval Street which is said to be one of the first brick homes
        built in Claxton in 1939. In June 2016, the Chamber moved to its
        present-day location at 302 West Railroad Street adjacent to Claxton
        City Hall. <br />
        <br />
        The Chamber is proud to have been a part of the growth and progress of
        Evans County during the past 6 decades and looks forward to continued
        business growth, economic development and success for the community.
        <br />
        <br />
        <br />
      </p>
      <h2>Member Testimonals</h2>
      <p>
        <br />
        "As a third- generation business owner, I understand the vital role the
        Chamber of Commerce plays in making Claxton and Evans County a great
        place to do business. The Chamber staff helped me prepare a successful
        Tourism Product Development grant application that has enabled me to
        provide an outdoor seating area for my customers. Little did I know in
        2019 that outdoor seating would be so significant in today’s hospitality
        industry." <br />
        <span css={{ fontSize: `14px`, display: "block", paddingTop: `6px` }}>
          - John Womble, Georgia Fruitcake Company
        </span>
        <br />
        <br />
      </p>
      <p>
        "I am proud to say I have been a chamber member for 35 years. Membership
        in the Chamber of Commerce is one of the best investments you can make,
        not only for your business, but for the community. Whether you invest
        your money, time, or both, partnering with the Chamber is an investment
        in the future of our community." <br />
        <span css={{ fontSize: `14px`, display: "block", paddingTop: `6px` }}>
          - Bruce Purcell, Quik Kut Distributors
        </span>
        <br />
        <br />
      </p>
    </div>
  </AboutLayout>
)

export default AboutIndexPage
